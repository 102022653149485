import './App.css';
import { Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Motus from './pages/Motus';
import Home from './pages/Home';

function App() {
  return (
    <>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/motus" element={<Motus />} />
        </Routes>
      </div>
    </>
  );

}

export default App;
