import React, { useState, useEffect } from 'react';
import './Motus.css';
import { Cookies } from 'react-cookie';

function Motus() {
  const [word, setWord] = useState('');
  const [guess, setGuess] = useState('');
  const [feedback, setFeedback] = useState('');
  const [endMessage, setEndMessage] = useState('');
  const [nbTries, setNbTries] = useState(5);
  const [previousReturnValue, setPreviousReturnValue] = useState<React.ReactNode[][]>([]);
  const [isEnd, setIsEnd] = useState(false);
  const [length, setLength] = useState();
  const cookies = new Cookies();

  const dailyGuessesCookie = cookies.get('daily_guesses');
  const lastPlayedDateCookie = cookies.get('last_played_date');

  function getMidnightExpiration() {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return today;
  }

  const midnightExpiration = getMidnightExpiration();
  if (!dailyGuessesCookie) {
    cookies.set('daily_guesses', 0, { expires: midnightExpiration, path: '/'});
  }
  if (!lastPlayedDateCookie) {
    const today = new Date().toISOString().split('T')[0];
    cookies.set('last_played_date', today, { expires: midnightExpiration, path: '/'});
  }

  if (lastPlayedDateCookie !== new Date().toISOString().split('T')[0]) {
    cookies.set('daily_guesses', 0, { expires: midnightExpiration, path: '/'});
  }

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL || 'https://motus-cs-b4jx.vercel.app';
    fetch(url + '/players/daily')
      .then(response => (response.json()))
      .then(data => {
        setWord(data.word);
        setLength(data.word.length);
        if (dailyGuessesCookie > 5) {
          setEndMessage(`Vous avez dépassé le nombre de tentatives autorisées pour aujourd'hui.<br>Le joueur d'aujourd'hui était : <div className='dailyWord' style='font-weight: bold;  '> ${data.word} </div>Revenez demain pour essayer à nouveau !`);
          setIsEnd(true);
        }
      })
      .catch(error => console.error(error));
  }, [dailyGuessesCookie]);

  const checkGuess = () => {
    var normalizedGuess: string[] = guess.split('').map(letter => letter.toLowerCase());
    var normalizedWord: string[] = word.split('').map(letter => letter.toLowerCase());
    var returnValue: React.ReactNode[] = [];

    if (guess.length !== word.length) {
      setFeedback(`Le mot doit comporter exactement ${word.length} lettres.`);
      return;
    }

    if (guess.toLowerCase() === word.toLowerCase()) {
      console.log('Bravo, vous avez trouvé le mot !');
      setFeedback('Bravo, vous avez trouvé le mot !');
      setNbTries(5);
      for (let i = 0; i < word.length; i++) {
        returnValue.push(<span key={i} style={{ color: 'green' }}>{word[i]}</span>);
      }
      setPreviousReturnValue([...previousReturnValue, returnValue]);
      cookies.set('daily_guesses', 6, { expires: midnightExpiration, path: '/'});
      cookies.set('last_played_date', new Date().toISOString().split('T')[0], { expires: midnightExpiration, path: '/'});
      return;
    }

    for (let i = 0; i < word.length; i++) {
      if (normalizedGuess[i] === normalizedWord[i]) {
        returnValue.push(<span key={i} style={{ color: 'green' }}>{normalizedGuess[i]}</span>);
        normalizedGuess[i] = '';
        normalizedWord[i] = '';
      } else {
        returnValue.push(<span key={i} style={{ color: 'red' }}>{normalizedGuess[i]}</span>);
      }
    }

    for (let i = 0; i < normalizedGuess.length; i++) {
      if (normalizedGuess[i] === '') {
        continue;
      }
      if (normalizedWord.includes(normalizedGuess[i])) {
        returnValue[i] = <span key={i} style={{ color: 'orange' }}>{normalizedGuess[i]}</span>;
        normalizedWord[normalizedWord.indexOf(normalizedGuess[i])] = '';
      }
    }
    setFeedback(`Réessayez, il vous reste ${nbTries} essais.`);
    setNbTries(nbTries - 1);
    setPreviousReturnValue([...previousReturnValue, returnValue]);
    cookies.set('daily_guesses', dailyGuessesCookie + 1, { expires: midnightExpiration, path: '/'});
    if (nbTries <= 0) {
      setFeedback(`Perdu ! Le mot était "${word}".`);
      setNbTries(5);
      setPreviousReturnValue([...previousReturnValue]);
      cookies.set('daily_guesses', 6, { expires: midnightExpiration, path: '/'});
      cookies.set('last_played_date', new Date().toISOString().split('T')[0], { expires: midnightExpiration, path: '/'});
      return;
    }
  };

  return (
    <div className="Motus">
      <h1>Motus - Counter-Strike</h1>
      <p>Trouve le nom du joueur du jour, avec {length} lettres :</p>
      <p>Ce ne sont actuellement que des joueurs de 2024.</p>
      <input
        type="text"
        value={guess}
        onChange={(e) => !isEnd && setGuess(e.target.value)}
        maxLength={length}
        disabled={isEnd}
      />
      <button onClick={() => !isEnd && checkGuess()} disabled={isEnd}>Vérifier</button>
      <div className='Responses'>
        {previousReturnValue.map((guesses, index) => (
          <div key={index}>
            {guesses.map((guess, index) => (
              <span key={index}>{guess}</span>
            ))}
          </div>
        ))}
      </div>
      <p>{feedback}</p>
      <div className='endMessage' dangerouslySetInnerHTML={{__html: endMessage}}></div>
    </div>
  );
}

export default Motus;
